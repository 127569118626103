<template>
  <span
    v-purify="icon"
    class="nuxt-icon"
    :class="{ 'nuxt-icon--fill': filled, 'nuxt-icon--stroke': hasStroke && !filled }"
    :style="{ width: `${width}px`, height: `${height}px` }"
  />
</template>

<script setup lang="ts">
import { ref, watchEffect } from '#imports';
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  filled: {
    type: Boolean,
    default: false,
  },
  height: {
    type: Number,
    default: 24,
  },
  width: {
    type: Number,
    default: 24,
  },
});

const icon = ref<string | Record<string, any>>('');
let hasStroke = false;
async function getIcon() {
  try {
    const iconsImport = import.meta.glob('assets/icons/**/**.svg', {
      as: 'raw',
      eager: false,
    });
    const rawIcon = await iconsImport[`/assets/icons/${props.name}.svg`]();
    if (rawIcon.includes('stroke')) {
      hasStroke = true;
    }
    /**
     * Add Width and height to rawIcon
     */
    // const width = props.width ? `width="${props.width}"` : '';
    // const height = props.height ? `height="${props.height}"` : '';
    const rawIconWithWidthAndHeight = rawIcon; // .replace('<svg', `<svg ${width} ${height}`);

    icon.value = rawIconWithWidthAndHeight;
  } catch {
    console.error(`[nuxt-icons] Icon '${props.name}' doesn't exist in 'assets/icons'`);
  }
}
await getIcon();
watchEffect(getIcon);
</script>

<style>
.nuxt-icon svg {
  margin-bottom: 0.125em;
  vertical-align: middle;
  height: 1rem;
  width: 1rem;
}
.nuxt-icon.nuxt-icon--fill,
.nuxt-icon.nuxt-icon--fill * {
  fill: currentColor !important;
}
</style>
